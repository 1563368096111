import UserAnalyticsClient from '../../frontend-common-libs/src/user-analytics/UserAnalyticsClient';
import AnonymizedString from '../../frontend-common-libs/src/utils/AnonymizedString';
import InstrumentTrackingToggle from './InstrumentTrackingToggle';

export default class OpenInstrumentDetailsTrackingEvent {
  private readonly deviceType: string;

  public constructor() {
    this.deviceType = 'PTCTempo';
  }

  public track(deviceModel: string, instrumentId: string): void {
    const params = {
      deviceType: this.deviceType,
      instrumentId: new AnonymizedString(instrumentId).toString(),
      deviceModel
    };

    const instrumentTrackingToggle = new InstrumentTrackingToggle(instrumentId);

    if (instrumentTrackingToggle.isEnabled())
      UserAnalyticsClient.getInstance().track('conventional-pcr-open-instrument-details', params);
  }
}
