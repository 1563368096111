import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { ImmutableMap, InstrumentItem } from '../../frontend-common-libs/src/common/types';
import { CommonInstrumentRow } from '../../frontend-common-libs/instrument-row';
import InstrumentFacade, {
  BlockEnum,
  InstrumentModelEnum,
  isRunStatusDisplayed
} from '../common/InstrumentFacade';
import ConventionalPCRInstrumentTypeIcon from './ConventionalPCRInstrumentTypeIcon';
import InstrumentDetails from './InstrumentDetails';
import InstrumentBlockRow from './InstrumentBlockRow';
import InstrumentDetailsExpandedEvent, {
  InstrumentDetailsExpandedEventParams
} from '../../user-analytics/fleet-management/InstrumentDetailsExpandedEvent';

export const detailsUnavailable = 'N/A';

export type Props = {
  instrument: ImmutableMap<InstrumentItem>;
};

export default function InstrumentRow({ instrument }: Props) {
  const instrumentIcon = <ConventionalPCRInstrumentTypeIcon type={instrument.get('type')} />;
  const instrumentFacade = new InstrumentFacade(instrument);
  const {
    instrumentModel,
    modelName,
    instrumentStatus,
    timeRemaining,
    statusIcon,
    details,
    runCurrentCycle,
    runTotalCycles,
    runCurrentStep,
    runTotalSteps,
    runId,
    runUserId
  } = instrumentFacade;
  const softwareVersion = details.softwareVersion ?? detailsUnavailable;

  const instrumentId = instrument.get('id');
  const instrumentName = instrument.get('name');
  const expandedRowTopPosition = '-9px';

  const offlineTimeout = useRef<any>();
  const [instrumentState, setInstrumentState] = useState(``);

  // After 5 minutes of no shadow/IoT message from the instrument, the
  // InstrumentFacade will change instrumentStatus to Offline.
  // The useEffect timeout renders the instrument row after the timeout period
  // to reflect updated state of the InstrumentFacade.
  useEffect(() => {
    let timeout = instrumentFacade.timeoutUntilExpiration();
    if (instrumentModel === InstrumentModelEnum.PTCTempo4848) {
      timeout = instrumentFacade.dualBlockInstrumentTimeout(timeout);
    }
    if (timeout > 0) {
      offlineTimeout.current = setTimeout(() => {
        setInstrumentState(`${instrumentStatus},${timeRemaining}`);
      }, timeout);
    }
    return () => {
      if (offlineTimeout.current) {
        setInstrumentState(``);
        clearTimeout(offlineTimeout.current);
      }
    };
  }, [instrumentStatus, timeRemaining, instrumentState]);

  const trackEvent = () => {
    const eventObject = {
      deviceType: 'PTCTempo',
      model: instrumentModel,
      instrumentId,
      instrumentStatus
    } as unknown as InstrumentDetailsExpandedEventParams;

    if (runId) {
      eventObject.runId = runId;
    }

    new InstrumentDetailsExpandedEvent().trackEvent(eventObject);
  };

  function instrumentRow(name: string): ReactElement {
    return (
      <CommonInstrumentRow
        id={instrumentId}
        displayModel={modelName}
        expandedRowTopPosition={expandedRowTopPosition}
        instrumentName={name}
        instrumentNameLine2={undefined}
        instrumentStatus={instrumentStatus}
        timeRemaining={timeRemaining}
        statusIcon={statusIcon}
        instrumentIcon={instrumentIcon}
        additionalOnClickEventHandler={trackEvent}
      >
        <InstrumentDetails
          serialNumber={details.serialNumber}
          instrumentId={instrumentId}
          displayModel={modelName || detailsUnavailable}
          instrumentName={instrumentName}
          softwareVersion={softwareVersion}
          mainFirmwareVersion={details.mainFirmwareVersion || detailsUnavailable}
          powerFirmwareVersion={details.powerFirmwareVersion || detailsUnavailable}
          lidFirmwareVersion={details.lidFirmwareVersion || detailsUnavailable}
          apiVersion={details.apiVersion || detailsUnavailable}
          imageVersion={details.imageVersion || detailsUnavailable}
          instrumentModel={instrumentModel || detailsUnavailable}
          runCurrentCycle={runCurrentCycle}
          runTotalCycles={runTotalCycles}
          runCurrentStep={runCurrentStep}
          runTotalSteps={runTotalSteps}
          isRunStatusDisplayed={isRunStatusDisplayed(instrumentStatus, softwareVersion)}
          runId={runId}
          runUserId={runUserId}
        />
      </CommonInstrumentRow>
    );
  }

  function getRowForModel(): ReactElement {
    if (instrumentModel === InstrumentModelEnum.PTCTempo4848) {
      return (
        <>
          <InstrumentBlockRow
            block={BlockEnum.BlockA}
            instrument={instrument}
            expandedRowTopPosition={expandedRowTopPosition}
          />
          <InstrumentBlockRow
            block={BlockEnum.BlockB}
            instrument={instrument}
            expandedRowTopPosition={expandedRowTopPosition}
          />
        </>
      );
    }
    return <>{instrumentRow(instrumentName)}</>;
  }

  return getRowForModel();
}
