import UserAnalyticsClient from '../../frontend-common-libs/src/user-analytics/UserAnalyticsClient';
import { NullableString } from '../../frontend-common-libs/src/common/nullable-types';

export type InstrumentDetailsExpandedEventParams = {
  deviceType: string;
  model: string;
  instrumentId: string;
  instrumentStatus: string;
  runId?: NullableString;
};

export default class InstrumentDetailsExpandedEvent {
  private static readonly INSTRUMENT_DETAILS_EXPANDED_EVENT_NAME =
    'ptc-tempo-instrument-row-expanded';

  // eslint-disable-next-line class-methods-use-this
  public trackEvent(params: InstrumentDetailsExpandedEventParams): void {
    UserAnalyticsClient.getInstance().track(
      InstrumentDetailsExpandedEvent.INSTRUMENT_DETAILS_EXPANDED_EVENT_NAME,
      {
        ...params
      }
    );
  }
}
