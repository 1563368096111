import React, { Component, ReactElement } from 'react';
import classNames from 'classnames';
import TRow from '../src/components/common/tables/TRow';
import TCell from '../src/components/common/tables/TCell';
import collapseIcon from './assets/collapse-icon-blue.svg';
import expandIcon from './assets/expand-icon-blue.svg';
import { NullableString } from '../src/common/nullable-types';
import { InstrumentStatusEnumValue } from '../../instruments/utils/instrumentUtils';

export interface Props {
  id: string;
  displayModel: NullableString;
  instrumentName: string;
  instrumentNameLine2?: string;
  instrumentStatus: InstrumentStatusEnumValue | NullableString;
  timeRemaining: string;
  expandedRowTopPosition?: string;
  statusIcon: NullableString;
  instrumentIcon: ReactElement;
  children?: ReactElement;
  additionalOnClickEventHandler?: () => void;
}

export interface State {
  isExpanded: boolean;
}

export default class CommonInstrumentRow extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isExpanded: false };
  }

  onRowKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (
      event.key === 'Enter' ||
      event.key === ' ' ||
      event.key === 'Spacebar' // support older browsers
    ) {
      this.toggleExpand();
    }
  };

  toggleExpand = () => {
    let { isExpanded } = this.state;
    isExpanded = !isExpanded;
    this.setState({ isExpanded });

    const { additionalOnClickEventHandler } = this.props;

    if (additionalOnClickEventHandler && isExpanded) {
      additionalOnClickEventHandler();
    }
  };

  renderStatusIcon = () => {
    const { statusIcon, instrumentStatus } = this.props;
    return statusIcon != null ? (
      <img src={statusIcon} alt={instrumentStatus || ''} className="status-img" />
    ) : null;
  };

  displayInstrumentBlock = () => {
    const { instrumentNameLine2 } = this.props;
    return instrumentNameLine2 ? (
      <>
        <br />
        {instrumentNameLine2}
      </>
    ) : null;
  };

  render() {
    const {
      id,
      displayModel,
      instrumentName,
      instrumentStatus,
      timeRemaining,
      instrumentIcon,
      children: instrumentDetails,
      expandedRowTopPosition
    } = this.props;

    const { isExpanded } = this.state;
    const expandCollapseImg = isExpanded ? collapseIcon : expandIcon;
    const expandCollapseTitle = isExpanded ? 'collapse' : 'expand';

    return (
      <>
        <TRow
          id={`inst-row_${id}`}
          tabIndex={0}
          className={classNames({ 'expanded-row': isExpanded })}
          onClick={this.toggleExpand}
          onKeyDown={this.onRowKeyDown}
        >
          <TCell title={displayModel}>{instrumentIcon}</TCell>
          <TCell className="cell-text" title={displayModel}>
            {displayModel}
          </TCell>
          <TCell className="name-cell" title={instrumentName}>
            {instrumentName}
            {this.displayInstrumentBlock()}
          </TCell>
          <TCell className="status-cell cell-text" title={instrumentStatus ?? 'Unknown'}>
            {this.renderStatusIcon()}
            {instrumentStatus ?? '- - -'}
          </TCell>
          <TCell className="cell-text" title={timeRemaining}>
            {timeRemaining}
          </TCell>
          <TCell>
            <button
              className="expand-collapse-button no-border-button no-focus-outline-on-click show-on-hover"
              title={expandCollapseTitle}
              disabled
              type="button"
            >
              <img src={expandCollapseImg} alt={expandCollapseTitle} />
            </button>
          </TCell>
        </TRow>
        {isExpanded && (
          <TRow
            className="details-row"
            style={expandedRowTopPosition ? { top: expandedRowTopPosition } : {}}
          >
            <TCell colSpan="5" className="details-cell">
              <div id={`inst-details_${id}`} className="instrument-details">
                {instrumentDetails}
              </div>
            </TCell>
          </TRow>
        )}
      </>
    );
  }
}
